<template>
  <div
    v-show="true"
    class="dialog-mask">
    <v-container
      fill-height
      fluid
      grid-list-xl>
      <v-layout
        wrap
      >
        <v-flex
          class="d-flex justify-center"
          xs12
          md12
        >
          <material-card
            max-width="600"
            max-height="440"
            color="green"
            title="用户登录"
            text="请与系统管理员联系获取用户名和密码"
          >
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-container py-0>
                <v-layout wrap>
                  <v-flex
                    xs12
                    md12
                  >
                    <v-text-field
                      v-model="username"
                      :counter="20"
                      :rules="nameRules"
                      label="用户名"
                      class="purple-input"
                      autofocus
                      clearable
                      @keyup.enter="login"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    md12
                  >
                    <v-text-field
                      v-model="password"
                      :append-icon="showHide.UserPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showHide.UserPassword ? 'text' : 'password'"
                      :counter="20"
                      :rules="pwdRules"
                      hint="密码最少6位"
                      label="密码"
                      clearable
                      @click:append="showHide.UserPassword = !showHide.UserPassword"
                      @keyup.enter="login"
                    />
                  </v-flex>
                  <v-flex
                    xs4
                    md4
                  >
                    <v-text-field
                      v-model="verificationCode"
                      label="验证码"
                      clearable
                      @keyup.enter="login"
                    />
                  </v-flex>
                  <v-flex
                    xs4
                    md4
                  >
                    <img
                      :src="codeImgUrl"
                      alt="验证码"
                      @click="getKaptcha()"
                    >
                  </v-flex>
                  <v-flex
                    xs12
                    text-xs-right
                  >
                    <v-btn
                      :disabled="!valid"
                      class="mx-0 font-weight-light"
                      color="success"
                      @click="login"
                    >
                      登录
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
      // username: 'laiwenhai',
      // password: '666666',
      username: '',
      password: '',
      random: '',
      verificationCode: '',
      codeImgUrl: '',
      showHide: {
        UserPassword: false
      },
      valid: true,
      nameRules: [
        v => !!v || '请输入用户名',
        v => (v && v.length <= 20) || '最多允许20字符',
        v => (v || '').indexOf(' ') < 0 || '不允许空格键'
      ],
      pwdRules: [
        v => !!v || '请输入密码',
        v => (v && v.length <= 20) || '最多允许20字符',
        v => (v || '').indexOf(' ') < 0 || '不允许空格键'
      ]
    }
  },
  created () {
    this.$session.clear()
    this.getKaptcha()
  },
  methods: {
    getKaptcha () {
      let random = Math.ceil(10000 + Math.round(Math.random() * 90000))
      console.log('getKaptcha() ' + random)
      this.random = random
      this.codeImgUrl = this.$store.state.app.url + '/admin/getKaptcha?random=' + random
    },
    login () {
      let form = {
        'username': this.username,
        'password': this.password,
        'random': this.random,
        'verificationCode': this.verificationCode
      }
      // console.log('login() ', form)
      this.$http
        .post(this.$store.state.app.url + '/admin/login', form)
        .then(res => {
          console.log(res)
          let data = res.data
          if (data.errCode === 0) {
            this.$router.push({ path: '/firmwares' })
            this.$session.set('signIn', data.result)
            console.log(data.result)
            this.$store.state.app.signIn = data.result
          } else {
            console.log(data.errMsg)
            this.$snackbar.error('登录失败 - ' + data.errMsg)
          }
        }).catch(error => {
          // console.log(error)
          this.$httpCatch(error)
        })
    }
  }
}
</script>
<style scoped lang="scss">
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0s;
  }
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
  .dialog-mask {
    background-color: #eeeeee;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    display: table;
    animation-duration: 0s;
  }
</style>
